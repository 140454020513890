<template>
  <section class="card-container">
    <a id="mediaReport-block" class="relative" href="#" style=" top: -90px" />
    <div class="card-content">
      <div class="flex justify-between items-center">
        <p class="title">媒體報導</p>
        <BaseElSwitch
          v-model="activated"
          active-text="顯示"
          inactive-text="不顯示"
        />
      </div>
      <p class="hint">最多2個報導。</p>

      <BaseElForm ref="form" :model="formData" :rules="formRules">
        <div v-for="count in bannerCount" :key="count">
          <div class="flex justify-between">
            <BaseElFormItem label="報導連結" :prop="`carousel_${count}.link`">
              <BaseElInput v-model="formData[`carousel_${count}`].link" />
            </BaseElFormItem>
            <div class="flex justify-end">
              <img v-if="count+1 > 2" src="@/assets/icon/trash.svg" class="cursor-pointer" @click="delBanner(count + 1)">
            </div>
          </div>
          <BaseElButton type="primary" style="margin-bottom: 18px" :loading="formData[`carousel_${count}`].loading" @click="getLinkMeta(count)">載入資訊</BaseElButton>
          <BaseElFormItem label="報導圖片" :prop="`carousel_${count}.img`" class="flex flex-col">
            <UploadButton
              :img="formData[`carousel_${count}`].img"
              :isAvatar="false"
              width="180px"
              height="180px"
              @change="loadImg"
              @action="imgTarget = `carousel_${count}.img`"
            />
          </BaseElFormItem>
          <BaseElFormItem label="標題" :prop="`carousel_${count}.title`">
            <BaseElInput v-model="formData[`carousel_${count}`].title" />
          </BaseElFormItem>
          <BaseElFormItem label="簡述" :prop="`carousel_${count}.description`">
            <BaseElInput v-model="formData[`carousel_${count}`].description" />
          </BaseElFormItem>

          <hr>
        </div>

        <div class="add-btn-container">
          <AddButton v-if="bannerCount < 2" style="width: 444px" @click="addBanner" />
          <!-- <BaseElButton v-if="bannerCount < 2" class="add-btn" @click="addBanner">新增 ＋</BaseElButton> -->
        </div>
      </BaseElForm>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
  </section>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import imageMixin from '@/mixin/image'
import { GetLinkMeta } from '@/api/ecommerce/pageSetting'
import { mapGetters } from 'vuex'
import { noEmptyRules, urlRules } from '@/validation'
import { find, forEach, some } from 'lodash'

export default {
  name: 'MediaReport',
  components: { ImageCropper, UploadButton },
  mixins: [imageMixin],
  props: ['pageConfig'],
  computed: {
    ...mapGetters(['shop']),
    compactData () {
      const banner = {}
      banner.carousel = []
      for (let c = 0; c < this.bannerCount; c++) {
        const carouse = `carousel_${c + 1}`
        const data = this.formData[carouse]
        if (data) {
          const newsData = {
            link: data.link,
            imageId: data.img?.id,
            title: data.title,
            description: data.description,
          }
          if (!this.activated && !some(newsData, item => !item)) banner.carousel.push(newsData)
          else if (this.activated) banner.carousel.push(newsData)
        }
      }
      return banner
    },
    composeData () {
      return {
        enable: this.activated,
        type: 'news',
        news: this.compactData,
      }
    },
  },
  watch: {
    composeData (data) {
      this.$emit('update', data)
    },
  },
  data: () => ({
    bannerCount: 1,
    activated: false,
    formData: {
      carousel_1: {
        loading: false,
        link: null,
        img: null,
        title: null,
        description: null,
      },
    },
    formRules: {
      'carousel_1.link': [noEmptyRules()],
      'carousel_1.img': [noEmptyRules()],
      'carousel_1.title': [noEmptyRules()],
      'carousel_1.description': [noEmptyRules()],
    },
  }),
  mounted () {
    this.syncConfig()
    this.$store.commit('SET_FORM_REF', {
      form: 'mediaReportForm',
      ref: this.$refs.form,
    })
  },
  methods: {
    async getLinkMeta (count) {
      this.formData[`carousel_${count}`].loading = true
      const [res, err] = await GetLinkMeta({
        shopId: this.shop,
        link: this.formData[`carousel_${count}`].link,
      })
      if (err) {
        this.formData[`carousel_${count}`].loading = false
        this.$message.error(err)
        return
      }
      this.formData[`carousel_${count}`].title = res.title
      this.formData[`carousel_${count}`].img = res.Image
      this.formData[`carousel_${count}`].description = res.description
      this.formData[`carousel_${count}`].loading = false
    },

    delBanner (index) {
      const keys = Object.keys(this.formData)
      this.$delete(this.formData, keys[index])
      this.bannerCount -= 1
    },

    addBanner () {
      if (this.bannerCount === 2) return
      this.bannerCount += 1
      this.$set(this.formData, `carousel_${this.bannerCount}`, {
        link: null,
        img: null,
        title: null,
        description: null,
      })

      this.$set(this.formRules, `carousel_${this.bannerCount}.link`, [
        noEmptyRules(),
        urlRules(),
      ])
      this.$set(this.formRules, `carousel_${this.bannerCount}.img`, [
        noEmptyRules(),
      ])
      this.$set(this.formRules, `carousel_${this.bannerCount}.title`, [
        noEmptyRules(),
      ])
      this.$set(this.formRules, `carousel_${this.bannerCount}.description`, [
        noEmptyRules(),
      ])
    },

    syncConfig () {
      const config = find(this.pageConfig, { type: 'news' })
      if (!config) return
      this.activated = config.enable

      forEach(config.news.carousel, (banner, index) => {
        this.bannerCount = index + 1
        if (!this.formData[`carousel_${index + 1}`]) this.formData[`carousel_${index + 1}`] = {}
        this.$set(this.formData[`carousel_${index + 1}`], 'link', banner.link)
        this.$set(this.formData[`carousel_${index + 1}`], 'title', banner.title)
        this.$set(this.formData[`carousel_${index + 1}`], 'description', banner.description)
        this.$set(this.formData[`carousel_${index + 1}`], 'img', banner.image)
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__label {
  @apply text-left;
}
.title {
  @apply font-medium text-[24px] leading-[34.75px] relative pl-[11px];
}
.title::before {
  content:'';
  @apply w-[3px] h-[28px] bg-primary-100 absolute left-0 ;
  top: calc(50% - 12px);
}

.hint {
  @apply text-sub text-gray-80 mt-[15px];
}

.add-btn-container {
  @apply flex justify-center items-center mt-[24px] mb-[12px] w-full;
}
.add-btn {
  @apply w-full max-w-[444px] rounded-[5px];
}
</style>
